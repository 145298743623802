<template>
    <div class="fl_c al_c mar_bottom" :style="{'background-color': ($common.mobile()&&isweixin ? '#f0f0f0':'')}">
        <div class="fl_r ju_b contain" v-if="!$common.mobile()">
            <div class="sliderSty">
                <side-bar/>
            </div>
            <div class="content">
                <div v-if="consultList.length>0" class="pad_around">
                    <div v-if="isContent&&articleDetail" class="fl_c al_c">
                        <div class="contentTitle">
                            {{articleDetail.title}}
                        </div>
                        <div class="contentTime">
                            {{articleDetail.showTime}}
                        </div>
                        <img class="contentImg" v-if="articleDetail.titleImg" :src="articleDetail.titleImg" alt="">
                        <div class="contentSty" v-html="articleDetail.content"></div>
                    </div>
                    <div v-else>
                        <div v-for="(item,index) in consultList" :key="index" @click="goPage(item)"
                             class="fl_r ju_b listItem">
                            <div> {{item.title}}</div>
                            <div v-if="item.showTime">{{item.showTime.substring(0,10)}}</div>
                        </div>
                    </div>
                </div>
                <div v-if="formalItem.name=='下载中心'" class="pad_around">
                    <div class="fl_c al_c">
                        <div class="contentTitle">
                            下载中心
                        </div>
                        <div v-for="(item,index) in downloadList" :key="index"
                             class="list fl_r ju_b" @click="downloadNow(item.file1)">
                            <div class="fileName">{{item.fileName}}</div>
                            <div style="color: #409eff" v-if="!$common.mobile()" class="downloadText">下载</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!----------------------------------------------------------------------------------------------------------------->
        <div class="fl_c ju_b contain" style="width: 100%;margin-top: 50px" v-if="$common.mobile()&&!isweixin">
            <div class="secondTabBox">
                <div class="topTitle">
                    {{formalItem.name}}
                </div>
            </div>
            <div class="content" style="width: 100%;height: auto;margin-top: 34px">
                <div v-if="consultList.length>0" class="pad_around">
                    <div v-if="isContent" class="fl_c al_c">
                        <div class="contentTitle">
                            {{articleDetail.title}}
                        </div>
                        <div class="contentTime">
                            {{articleDetail.showTime}}
                        </div>
                        <img class="contentImg" v-if="articleDetail.titleImg" :src="consultList[0].titleImg" alt="">
                        <div class="contentSty" v-html="articleDetail.content"></div>
                    </div>
                    <div v-else>
                        <div v-for="(item,index) in consultList" :key="index" @click="goPage(item)"
                             class="fl_r ju_b al_c listItem">
                            <div style="width: 70%;"> {{item.title}}</div>
                            <div v-if="item.showTime">{{item.showTime.substring(0,10)}}</div>
                        </div>
                    </div>
                </div>
                <div v-if="formalItem.name=='下载中心'" class="pad_around">
                    <div class="fl_c al_c">
                        <div class="contentTitle">
                            下载中心
                        </div>
                        <div v-for="(item,index) in downloadList" :key="index"
                             class="list fl_r ju_b" @click="downloadNow(item.file1)">
                            <div class="fileName">{{item.fileName}}</div>
                            <div style="color: #409eff" v-if="!$common.mobile()" class="downloadText">下载</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sliderSty" style="width: 100%;height: auto;">
                <side-bar/>
            </div>
        </div>
        <div class="fl_c ju_b contain" style="width: 100%;position: relative;background-color: #f0f0f0;height: 1000px"
             v-if="$common.mobile()&&isweixin">
            <img src="@/assets/img/tips.jpg" alt="">
        </div>
    </div>
</template>

<script>
    import SideBar from "../../components/SideBar/SideBar";
    import {getarticlesInfo, getDownload, getarticleDetail, getColumnTree} from "../../utils/api";

    export default {
        name: "secondPage",
        components: {SideBar},
        data() {
            return {
                consultList: [],
                isContent: false,
                downloadList: [],
                articleDetail: null,
                isweixin: false,
            }
        },
        computed: {
            formalItem: {
                get() {
                    return this.$store.state.formalItem
                },
                set(val) {
                },
            },
            childItem: {
                get() {
                    return this.$store.state.childItem
                },
                set(val) {
                },
            },
        },
        watch: {
            formalItem(newValue, oldValue) {
                if (newValue.subArticles.length < 1) {
                    this.consultList = []
                } else {
                    // this.getarticlesInfo(newValue.subArticles[0].id)
                }
            },
            childItem(newValue, oldValue) {
                if (newValue) {
                    this.getarticlesInfo(newValue.id)
                }

            }
        },
        mounted() {
            if (this.$route.query.columnId) {
                this.getColumnTree1(this.$route.query.columnId)
            }
            if (!this.$store.state.formalItem) {
                return this.$router.push('/')
            }
            // if (this.isWeixinBrowser()) {
            //     this.isweixin= true;
            //     this.$store.commit('setWeixing', true);
            // }else{
            //     this.$store.commit('setWeixing', false);
            // }
            window.scrollTo(0, 0);
            if (this.$route.query.id) {
                this.getColumnTree(this.$route.query.id)
            }
            this.getDownload()
            if (this.childItem) {
                this.getarticlesInfo(this.childItem.id)
            } else {
                if (this.formalItem.subArticles.length > 0) {
                    this.getarticlesInfo(this.formalItem.subArticles[0].id)
                } else {
                    this.getarticlesInfo(this.formalItem.id)
                }
            }
        },
        methods: {
            async getColumnTree1(columnId) {
                let res = await getColumnTree()
                res.data.map(item => {
                    item.showSecondTab = false
                    item.subArticles.map(item1 => {
                        if (item1.id == columnId) {
                            this.$store.commit('setFormalItem', item);
                            this.$store.commit('setChildItem', item1);
                        }
                    })
                })
            },
            // 判断是否为微信浏览器
            isWeixinBrowser() {
                let ua = navigator.userAgent.toLowerCase();
                return /micromessenger/.test(ua) ? true : false;
            },
            async getColumnTree(id) {
                let res = await getColumnTree()
                res.data.map(item => {
                    item.showSecondTab = false
                    if (item.id == id) {

                        this.$store.commit('setFormalItem', item);
                        this.$store.commit('setChildItem', item.subArticles[0]);
                    }
                })
                this.headerList = res.data
            },
            goPage(item) {
                this.$router.push({
                    path: "/detailPage",
                    query: {
                        articleId: item.id,
                        columnId: item.columnId
                    }
                });
            },
            async getarticleDetail(id) {
                let res = await getarticleDetail({
                    articleId: id
                })
                this.articleDetail = res.data
            },
            async getarticlesInfo(id) {
                if (id) {
                    let res = await getarticlesInfo({
                        columnId: id
                    })
                    this.isContent = res.data.length == 1 ? true : false
                    if (this.formalItem.subArticles.length < 1) {
                        this.consultList = []
                    } else {
                        this.consultList = res.data
                    }
                    if (res.data.length == 1) {
                        this.getarticleDetail(res.data[0].id)
                    }
                }

            },
            async getDownload() {
                let res = await getDownload()
                this.downloadList = res.data
            },
            downloadNow(e) {
                location.href = e
            },
        },
    }
</script>

<style scoped lang="scss">
    .contain {
        width: 1200px;
        margin-top: 10px;
        height: auto;

        .content {
            width: 930px;
            border: 1px solid #ccc;
            min-height: 800px;

            .contentTitle {
                font-size: 20px;
                font-weight: bold;
            }

            .contentTime {
                width: 100%;
                line-height: 30px;
                height: 30px;
                text-align: center;
                background: #f5f5f5;
                color: #999;
                margin: 20px 0;
            }

            .contentImg {
                width: 100%;
                height: 100%;
            }

            .contentSty {
                margin-top: 20px;
            }
        }
    }

    .sliderSty {
        width: 260px;
    }

    .listItem {
        padding: 20px 0;
        color: #666666;
        font-size: 15px;
        cursor: pointer;
    }

    .listItem:hover {
        color: #195B40;
    }

    .list {
        width: 80%;
        cursor: pointer;
        padding: 10px;

    }

    .list:hover {
        text-decoration: underline;
        color: #409eff
    }

    .fileName {

    }

    .downloadText {

    }

    .list1 {
        cursor: pointer;
        width: 100%;
        height: auto;

    }

    .secondTabBox {
        width: 100%;
        height: auto;
        border: 1px solid #DDDDDD;
        margin-bottom: 10px;
        position: fixed;

    }

    .topTitle {
        width: 100%;
        height: 34px;
        background-color: #195B40;
        font: 600 16px/34px "宋体";
        color: #fff;
        text-align: center;
    }
</style>

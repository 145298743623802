<template>
    <div class="containBox fl_c" :style="{'height': (!$common.mobile() ? '':'auto')}">
        <div class="secondTabBox" v-if="formalItem.subArticles.length>0&&formalItem.columnType!='01'">
            <div class="topTitle" v-if="!$common.mobile()">
                {{formalItem.name}}
            </div>
            <div :class="[activeId==item.id?'topList_active':'topList']" v-for="(item,index) in formalItem.subArticles"
                 :key="index" @click="chooseOne(item)">
                {{item.name}}
            </div>
        </div>
        <div class="hotNewsBox" v-if="!$common.mobile()">
            <div class="titleBox">热点新闻</div>
            <div v-for="(item,index) in hotArticlesArr" :key="index" @click="goPage(item)"
                 class="fl_r al_c itemSty">
                <div class="roundDot"></div>
                <div class="titleSty white_space_one">{{item.title}}</div>
            </div>
        </div>
        <div class="recommondBox" v-if="!$common.mobile()">
            <div class="titleBox">推荐阅读</div>
            <div v-for="(item,index) in recommendArticles" :key="index" @click="goPage(item)"
                 class="fl_r al_c itemSty">
                <div class="roundDot"></div>
                <div class="titleSty white_space_one">{{item.title}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import infoList from "../infoList/infoList";
    import {getarticlesInfo,hotArticles} from "../../utils/api";

    export default {
        name: "SideBar",
        props: ['list'],
        data() {
            return {
                activeId: 0,
                newsList:[],
                recommonList:[],
                arr: [
                    {
                    id: '1',
                    title: '100个全国种植业“三品一标”基地公布！'
                }, {
                    id: '2',
                    title: '100个全国种植业“三品一标”基地公布！'
                }, {
                    id: '3',
                    title: '100个全国种植业“三品一标”基地公布！'
                }, {
                    id: '4',
                    title: '100个全国种植业“三品一标”基地公布！'
                }, {
                    id: '5',
                    title: '100个全国种植业“三品一标”基地公布！'
                }, {
                    id: '6',
                    title: '100个全国种植业“三品一标”基地公布！'
                }, {
                    id: '7',
                    title: '100个全国种植业“三品一标”基地公布！'
                }, {
                    id: '8',
                    title: '100个全国种植业“三品一标”基地公布！'
                }, {
                    id: '9',
                    title: '100个全国种植业“三品一标”基地公布！'
                }],

                hotArticlesArr:[],
                recommendArticles:[],
            }
        },
        computed: {
            formalItem: {
                get() {
                    return this.$store.state.formalItem
                },
                set(val) {
                },
            },
            childItem: {
                get() {
                    return this.$store.state.childItem
                },
                set(val) {
                },
            },
        },
        watch: {
            formalItem: {
                handler: function (newValue, oldValue) {
                    if (newValue.subArticles.length > 0) {
                        this.activeId = newValue.subArticles[0].id
                    }
                },
                immediate: true,
            },
            // 'formalItem.id'(newValue, oldValue) {
            //     console.log(newValue,'newValuenewValue')
            //     if(this.formalItem.subArticles.length>0){
            //         this.activeId=this.formalItem.subArticles[0].id
            //     }
            // },
            childItem(newValue, oldValue) {
                if(newValue){
                    this.activeId = newValue.id
                }
            },
            activeId(newValue, oldValue) {
                this.formalItem.subArticles.map(item => {
                    if (item.id == newValue) {
                        this.$store.commit('setChildItem', item);
                    }
                })
            },
        },
        mounted() {
            this.hotArticles()
            this.getarticlesInfo()
            if (this.childItem) {
                this.activeId = this.childItem.id
            } else {
                if (this.formalItem.subArticles.length > 0) {
                    this.activeId = this.formalItem.subArticles[0].id
                }
            }

        },
        methods: {
            async hotArticles(){
              let res = await hotArticles()
                this.hotArticlesArr=res.data.hotArticles
                this.recommendArticles=res.data.recommendArticles
            },
            async getarticlesInfo() {
                let res1 = await getarticlesInfo({
                    columnId: '291'
                })
                this.newsList = res1.data
                let res2 = await getarticlesInfo({
                    columnId: '292'
                })
                this.recommonList = res2.data


            },
            chooseOne(data) {
                this.activeId = data.id
                this.$router.push({ path: '/secondPage', query: { columnId: data.id } })
                if (this.$router.history.current.name == 'DetailPage') {
                    this.formalItem.subArticles.map(item => {
                        if (item.id == data.id) {
                            this.$store.commit('setChildItem', item);
                        }
                    })

                }
            },
            goPage(item) {
                this.$router.push({
                    path: "/detailPage",
                    query: {
                        articleId: item.id,
                        columnId: item.columnId
                    }
                });
            }
        },
    }
</script>

<style scoped lang="scss">
    .containBox {
        width: 100%;
        height: 1200px;

        .secondTabBox {
            width: 100%;
            height: auto;
            border: 1px solid #DDDDDD;
            margin-bottom: 10px;
        }

        .hotNewsBox {
            width: 100%;
            height: 300px;
            border: 1px solid #DDDDDD;
            margin-bottom: 10px;

        }

        .recommondBox {
            width: 100%;
            height: 300px;
            border: 1px solid #DDDDDD;

        }
    }

    .topTitle {
        width: 100%;
        height: 34px;
        background-color: #195B40;
        font: 600 16px/34px "宋体";
        color: #fff;
        text-align: center;
    }

    .topList {
        width: 100%;
        height: 34px;
        line-height: 34px;
        text-align: center;
        font-size: 15px;
        color: #666666;
        cursor: pointer;
        border-top: 1px solid #ddd;
    }

    .topList_active {
        width: 100%;
        height: 34px;
        line-height: 34px;
        text-align: center;
        font-size: 15px;
        color: #195B40;
        cursor: pointer;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #195B40;
        font-weight: bold;
    }

    .titleBox {
        border-bottom: 2px solid #E6E7E6;
        height: 38px;
        line-height: 38px;
        font-weight: bold;
        font-size: 14px;
        padding-left: 20px;
    }

    .itemSty {
        padding: 6px 10px;
        cursor: pointer;
    }

    .titleSty {
        width: 220px;
        font-size: 12px;
        color: #666666;
    }

    .titleSty:hover {
        color: #195B40;
    }

    .roundDot {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #195B40;
        margin-right: 5px;
    }
</style>

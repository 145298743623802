<template>
    <div class="contain fl_c al_c">
        <div class="fl_r al_c itemSty" v-for="(item,index) in arr" :key="index" @click="goPage(item)">
            <div class="roundDot"></div>
            <div  class="white_space_one titleSty">{{item.title}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "infoList",
        components: {},
        props:['arr'],
        data() {
            return {
                // arr:[
                //     {
                //     id:1,
                //     title:'国商中心荣获《电商助农典型案例》国商中心荣获《电商助农典型案例》'
                // },{
                //     id:2,
                //     title:'国商中心荣获《电商助农典型案例》'
                // },{
                //     id:3,
                //     title:'国商中心荣获《电商助农典型案例》'
                // },{
                //     id:4,
                //     title:'国商中心荣获《电商助农典型案例》'
                // },{
                //     id:5,
                //     title:'国商中心荣获《电商助农典型案例》'
                // },{
                //     id:6,
                //     title:'国商中心荣获《电商助农典型案例》'
                // }]
            }
        },
        mounted() {

        },
        methods: {
            goPage(item) {
                this.$router.push({
                    path: "/detailPage",
                    query: {
                        articleId: item.id,
                        columnId: item.columnId
                    }
                });
            }
        },
    }
</script>

<style scoped lang="scss">
    .contain {
        width: 100%;
        height: 100%;
    }
    .roundDot{
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #195B40;
        margin-right:5px;
    }
    .itemSty {
        width: 95%;
        height: 35px;
        line-height: 35px;
        cursor: pointer;
    }
    .itemSty:hover{
        color: #195B40;
    }
    .titleSty {
        color: #666666;
        font-size: 15px;
        cursor: pointer;
        width: 256px;
        margin-left: 10px;
        padding: 2px 0;
    }
    .titleSty:hover{
        color: #195B40;
    }
</style>
